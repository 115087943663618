import React, { forwardRef, useEffect } from 'react';
import { formatPriceWithEuro } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';

const PaygreenGlobalForm = ({
  user,
  userEmail,
  userName,
  setUserName,
  setUserEmail,
  handleSaveCard,
  errorMessage,
  loading,
  cardNbComplete,
  cardExpComplete,
  cardCVCComplete,
  cartItems,
  onInitPaygreenEventListeners,
  initPaygreen,
  paymentEventTriggered,
  conecs,
  handleSubmit,
  save,
  paymentInputLoading,
  showPaymentFields,
}) => {
  const paygreen_endpoint = process.env.REACT_APP_PAYGREEN_API_ENDPOINT;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = paygreen_endpoint;
    script.async = true;
    script.onload = () => {
      onInitPaygreenEventListeners();
      if (!conecs || !conecs.buyerId) {
        initPaygreen();
      }
    }
    document.body.appendChild(script);
    return () => {
      paymentEventTriggered = false;
      document.body.removeChild(script);
    };
  }, []);

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setUserEmail(e.target.value);
  };

  return (
    <div
      id="paygreen-conecs"
      style={{ fontFamily: 'Open Sans' }}
      className=" slide-down-transition slide-down-content visible grey-bg"
    >
       <div
        className="mailformpayment mailformpayment--name shadow-[0px 1px 2px rgba(0, 0, 0, 0.25)] rounded-[1em] mt-8">
        <input className="shadow-md" maxLength="20" placeholder="Nom et prénom sur la carte" type="text"
               name="name" id="nomprenom" value={userName === ' ' ? '' : userName}
               onChange={handleInputChange}
               autoComplete="name" />
      </div>
      <div id="paygreen-container" style={{ display: 'none' }} />
      <div id="paygreen-methods-container" style={{ visibility: 'hidden' }} />

      {/*{paymentInputLoading && <CustomLoader/>}*/}
      {showPaymentFields && (
        <div className="paygreen-form">
          <div className="child1">
            <div className="mt-2" id="paygreen-pan-frame">
              {/*<img id="pan-check" className="absolute right-0 bottom-0 h-6 invisible"*/}
              {/*     src="/images/check-2.svg" />*/}
            </div>
          </div>
          <div className="date-and-crypto-container">
            <div className="child2">
              <div className="mt-2" id="paygreen-exp-frame">
                {/*<img*/}
                {/*  id="exp-check"*/}
                {/*  className="absolute right-0 bottom-0 h-6 invisible"*/}
                {/*  src="/images/check-2.svg"*/}
                {/*/>*/}
              </div>
            </div>
            <div className="paygreen-cvv-container child3 ">
              <div className="mt-2" id="paygreen-cvv-frame"></div>
              <img id="cvv-check" className="absolute right-0 bottom-0 h-6 invisible"
                   src="/images/check-2.svg" />
              <i data-feather="help-circle"></i>
              <img id="cvv-check" className="absolute right-0 bottom-0 h-6 invisible"
                   src="/images/check-2.svg" />
              <i data-feather="help-circle"></i>
            </div>
          </div>
        </div>
      )}
     
      {!user.email && (
        <div className="mailformpayment shadow-[0px 1px 2px rgba(0, 0, 0, 0.25)] rounded-[1em]t">
          <input className="shadow-md" placeholder="Email" type="text" name="name" id="nomprenom"
                 autoComplete="email"
                 value={userEmail === '' ? '' : userEmail} onChange={handleInputChange2} />
        </div>)}

      <div id="enregistrer" className={' flex items-center w-full ml-6'}>
        <input className="w-[0px] " id="paygreenCheckboxenregistrer" type="checkbox" checked={save}
               onChange={handleSaveCard} />
        <label htmlFor="paygreenCheckboxenregistrer" className="paygreenlabelenregistrer"></label>
        <label htmlFor="paygreenCheckboxenregistrer" className="flex items-center ml-6">
          <h3 className=" text-[ tracking-tighter] text-[1.4rem]"
              style={{ color: '#5A5A5A', fontWeight: 400 }}>
            Enregistrer pour la prochaine fois
          </h3>
        </label>
      </div>
      <div className="payment-error full no-bottom">{errorMessage}</div>
      <div className={'flex flex-col w-full'}>
        <button
          className={`payer_maintenant_btn flex items-center ${
            !cardCVCComplete || !cardExpComplete || !cardNbComplete
              ? 'pointer-events-none opacity-20'
              : ''
          }`}
          onClick={handleSubmit}
          disabled={!cardCVCComplete || !cardExpComplete || !cardNbComplete}
        >
            {loading ? (
              <>
                <p>Chargement...</p>
              </>
            ) : (
              <>
                <p>{formatPriceWithEuro(getOrderAmount(cartItems))}</p>
                <p className="font-light" style={{ fontFamily: 'geomanistmedium' }}>Payer maintenant</p>
              </>
            )}
          </button>
      </div>
    </div>
);
};
export default forwardRef(PaygreenGlobalForm);
