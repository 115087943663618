import axios from 'axios';
import { useContext } from 'react';
import { authContext } from '../context/authProvider';

const Config = () => {
  const { auth, setAuth } = useContext(authContext);
  const Instance = axios.create({
    baseURL: 'https://app-jilswebappstaff-hbrkedmp4q-od.a.run.app',

   // baseURL: "http://localhost:5000",
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return Instance;
};
export default Config;
