import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addToCart, getCartInfo } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import * as ScanditSDK from 'scandit-sdk';
import { BarcodePicker } from 'scandit-sdk';
import Scanner from './Scanner';
import CartLength from './CartLength';
import useAxiosInterceptors from '../axios/useAxios';
import { addUser } from '../actions/userActions';
import { Toast } from './Toast';
import { scanDisplay } from '../actions/scanActions';
import FooterCart from './FooterCart';
import Menu from './Menu';
import { ACCOUNT, HOME } from '../constants/routes';
import BackButton from './button/BackButton';
import { formatPrice } from '../utils/StringUtils';
import { notifySuccess, notifyWarning } from '../utils/Notify';

const Scan = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const location = useLocation()
  const { cartItems } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const user = useSelector((state) => state.user);
  const scanTimer = 1000;
  const focusref = useRef(null)
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();
  const [access, setAccess] = useState(true);
  const [scanner, setScanner] = useState(false);
  const [Code, setCode] = useState();
  const [product, setProduct] = useState();
  const [productToAdd, setProductToAdd] = useState();

  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [qty, setQty] = useState(1);
  const [overflowStyle, setOverflowStyle] = useState('overflow-hidden h-full');
  //const [isOnline, setIsOnline] = useState(navigator.onLine);
  //const prevOnlineStatus = useRef(isOnline);
  const cart = useSelector((state) => state.cart);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);
  const countItems = CartLength();
  const [isPaused, setIsPaused] = useState(countItems >= store?.item_limit ? true : false);
  const [isDestroy, setIsDestroy] = useState(false)

  const [cartFullPopup, setCartFullPopup] = useState(false);

  const animProductRef = useRef(null);
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;

  const handleScanPause = (scanPause) => {
    setIsPaused(scanPause)
  }

  useEffect(() => {

    if (countItems >= store.item_limit) {
      setIsPaused(true);
      setCartFullPopup(true);
    } else {
      setIsPaused(false);
      setCartFullPopup(false);
    }
  }, [countItems]);
  const ref = useRef();

  const refreshScan = () => {
    // setProduct()
    setAccess(true);
  };
  const getScanSettings = () => {
    return new ScanditSDK.ScanSettings({
                                         enabledSymbologies: ['ean8', 'ean13', 'upca', 'code128', 'qr'],
                                         codeDuplicateFilter: 2500,
                                         searchArea: { x: 0.025, y: 0.2, width: 0.95, height: 0.36 },
                                         maxNumberOfCodesPerFrame: 1,
                                       });
  };

  const incQty = () => {
    setQty(qty + 1);
  };
  const decQty = () => {
    setQty(qty - 1);
  };

  const deployScan = () => {
    if (nom.length > 0 && prenom.length > 0) {
      dispatch(addUser(nom, prenom, ''))
      localStorage.setItem('scanner', true);
      setScanner(true);
      setOverflowStyle('overflow-hidden');
    } else Toast('error', 'YOU SHALL NOT PASS')

  };

  const goBackUser = () => {
    console.log('user', user);
    if (user && user._id) {
      navigate(ACCOUNT)
    } else {
      navigate(HOME)
    }
  }

  useEffect(() => {
    const handleChange = () => {

      dispatch(scanDisplay(false))
    }
    return () => {
      window.removeEventListener('beforeunload', handleChange())
    }

  }, [])

  const addToCartHandler = () => {
    setLoading(false);
    if (countItems == 4) {
      console.log('y\'en a 4')
      setIsPaused(true);
    }

    addToCartAnim();
  };
  const addToDatabase = async () => {
    let transformedBarcode
    setLoading(true)
    const productName = document.getElementById('inputProductName').value;
    const productPrice = document.getElementById('inputProductPrice').value;
    const productCheckbox = document.getElementById('inputProductCheckbox');
    if(productCheckbox.checked){
      const prefix = Code.data.substring(0, 6);
      transformedBarcode = prefix + '0000000'
    }
    axiosInstance.post('/products/addNewProduct', {
      newProduct: {
        'Code_Barre': productCheckbox.checked ? transformedBarcode : Code.data,
        'storeId': store.id,
        'price': parseFloat(productPrice),
        'name': productName
      },
    })
                 .then((response) => {
                   setProductToAdd(0)
                   notifySuccess('Success', 'Article ajouté', 'Super')
                   setLoading(false);
                 })
                 .catch((error) => {
                   setProductToAdd(0)
                   if (error?.response?.data?.error && error?.response?.data?.error.length > 0) {
                     notifyWarning('Erreur', error?.response?.data?.error, 'D\'accord')
                   } else {
                     notifyWarning('Erreur', 'Une erreur inconnue est survenue', 'D\'accord')
                   }
                   setLoading(false)

                 })
                 .finally(() => {
                   setProductToAdd(0)
                   setLoading(false)
                 })
  };

  async function addToCartAnim() {
    //setIsPaused(true);
    console.log('debut anim cart');
    const footerCart = document.getElementById('footerCart');

    footerCart.classList.remove('shake');
    setIsNavbarHidden(true);
    getProductOffset();

    setTimeout(() => {
      if (product) {
        dispatch(addToCart(cartItems._id, product, qty, axiosInstance));
        setCode(0);
      }
      setProduct(false);
      footerCart.classList.add('shake');
    }, '750');

    footerCart.classList.remove('shake');
    console.log('fin anim cart');
    //setIsPaused(false);
  }

  const vidercache = async () => {
    // Efface dans le indexDB

    window.indexedDB.deleteDatabase('/scandit_sync_folder_preload');
    window.indexedDB.deleteDatabase('/scandit_sync_folder')
    localStorage.removeItem('scandit-device-id')
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.trim().startsWith('_') ? c : c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
    sessionStorage.clear();

    caches.open('my-cache').then(function (cache) {

      cache.keys().then(function (keys) {
        keys.forEach(function (request) {
          cache.delete(request);

        });
      }).then(function () {
        console.log('Cache "my-ache" vidé avec succès.');
        window.location.reload();
      });
    });
  }
  const getProductOffset = () => {
    if (product) {
      const element = document.getElementById('product_img');
      const offset = element.getBoundingClientRect();
      console.log('offseeeet');
      console.log(offset);

      const animProduct = animProductRef.current;
      // Set the position of the div based on offset values
      animProduct.style.top = `${offset.top}px`;
      animProduct.style.left = `${offset.left}px`;
      const footerCart = document.getElementById('footer-cart');
      if (footerCart) {
        const cartOffset = footerCart.getBoundingClientRect();

        console.log(cartOffset);

        console.log('------offset difference----');
        let translateXValue = 0;
        offset.left - cartOffset.left > 10
          ? (translateXValue = -(offset.left - cartOffset.left))
          : (translateXValue = 0);
        console.log(offset.left - cartOffset.left);
        let translateYValue = 0;
        offset.top - cartOffset.top > 10
          ? (translateYValue = -(offset.top - cartOffset.top))
          : (translateYValue = 0);
        console.log(offset.top - cartOffset.top);
        if (translateXValue !== 0 || translateYValue !== 0) {
          animProduct.style.setProperty('--translate-y', `${translateYValue}px`);
        }

        animProduct.style.setProperty('--translate-x', `${translateXValue}px`);
      }
    }

  };

  async function getProduct() {
    if (Code) {
      try {
        //setIsPaused(true)
        //alert("hello");
        setLoading(true);
        setIsNavbarHidden(false);
        console.log(Code)
        const { data } = await axiosInstance.get(
          `/products/${cartItems.storeId}/${Code.symbology}/${Code.data}`, {
            params: {
              orderId: cartItems._id
            }
          }
        );
        console.log(data)

        setProduct(data);
        setLoading(false);
        //setIsPaused(false)
        // setAccess(false);
        setQty(1);
        setTimeout(refreshScan, scanTimer);
        getProductOffset();
      } catch (err) {
        console.log(err);
        setLoading(false);
        setProductToAdd({
                          'Code_Barre': Code.data,

                        })
        // setCode();
        // alert("Article non trouvé, réessayez !");
        setAccess(true);
      }
    }
  }

  useEffect(() => {
    if (loading) {
      setIsPaused(true);
    } else {
      setIsPaused(false);
    }
  }, [loading]);

  useEffect(() => {
    if (!cartItems) {
      navigate('/');
    }
  }, [cartItems]);

  useEffect(() => {
    getProduct();
  }, [Code]);

  useEffect(() => {
    if (product) {
      // addToCartHandler()
      // setCode()
    }
  }, [product]);

  const productPopup = () => {
    if (product) {
      setProduct(false);
      setCode(0);
    }

  };
  const productToAddPopup = () => {
    if (productToAdd) {
      setProductToAdd(false);
      setCode(0);
    }

  };

  useEffect(() => {
    if (cart && cart.cartItems.isPaid) {
      if (store && store.id) {
        navigate(`/?ok=${store.id}`);
      } else {
        navigate('/');
      }
    }
  }, [cartItems]);

  const handleCheckMenu = (checkMenu) => {
    console.log('Checkbox payment changed:', checkMenu);
    product.menu = checkMenu

  };

  useEffect(() => {
              focusref.current.focus()
            }
    , [])

  const handleShowFooter = () => {
    ref.current.onClickInfooter()
  }
  return (
    <>
      {cartItems ? (
        <>
          <div ref={focusref} id="scan-main">
            <div className="min-h-full min-w-full  bg-black text-white overflow-hidden ">
              <>
                {loading ? (
                  <>
                    <div
                      className="absolute z-50 h-screen w-screen "
                      style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                    >
                      <div className="z-50 absolute left-2/4 top-[33%]  -translate-x-2/4 ">
                        <div className="lds-spinner white">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <button className="vider_cache" onClick={() => vidercache()}>Problème de scanner?</button>
                {store && store.id ? (
                  <div className="store_name_and_logo">

                    <div className="store_logo">
                      <img src={store.logo} />
                    </div>
                    <div className="store_name">
                      {store.name.split(' ')[0]}
                    </div>

                  </div>) : (
                  <></>
                )

                }

                {/* {scan()} */}

                <Scanner
                  onClick={productPopup}
                  preloadBlurryRecognition={true}
                  preloadEngine={true}
                  accessCamera={true}
                  guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
                  viewFinderArea={{ x: 0.2, y: 0.01, width: 0.6, height: 0.1 }}
                  onScan={(scanResult) => {
                    setCode(scanResult.barcodes[0]);
                    //scanResult.rejectCode(scanResult.barcodes[0]);
                  }}
                  pause={isPaused}
                  destroy={isDestroy}
                  onProcessFramze
                  scanSettings={getScanSettings()}
                  videoFit={BarcodePicker.ObjectFit.COVER}
                  playSoundOnScan={true}
                  enableCameraSwitcher={false}
                  enablePinchToZoom={false}
                  enableTapToFocus={true}
                  enableTorchToggle={false}
                  mirrorImage={true}
                  targetScanningFPS={10}
                  zoom={0}
                />

                <BackButton onClick={goBackUser} isAbsolute isWhite />
                <div className="absolute w-full h-fit text-center m-auto top-[60%] overflow-hidden">

                  <p
                    className="text-lg w-screen text-white"
                    onClick={productPopup}
                  >
                    Scanne le code-barre de tes produits
                  </p>

                </div>
                {cartFullPopup ? (

                  <div
                    id="cartFullPopup"
                    className="absolute z-100 min-w-full min-h-full left-0 top-0 h-full overflow-hidden flex justify-center items-center"
                  >
                    <div
                      id=""
                      className=" bg-white w-3/4 flex flex-col gap-8 p-8 rounded-[12px] "
                      style={{
                        boxShadow: '0 0 0 100vmax rgb(0 0 0 / 65%)',
                      }}
                    >
                      <div className="flex justify-center flex-col gap-10 items-center ">
                        <p className="text-2xl text-center geomanistBold mt-4 text-black">
                          Votre panier est plein, supprimez des articles
                          pour continuer à scanner.
                        </p>
                      </div>

                      <button
                        className="pikko-btn rounded-full mt-4 py-6 justify-self-end pikko-btn w-full text-center text-black relative">
                        <div className="text-2xl text-black" onClick={handleShowFooter}>
                          Se rendre dans le panier
                        </div>
                      </button>

                      <div className="flex items-center justify-around"></div>
                    </div>
                  </div>

                ) : (
                  <></>
                )}
                {/*<FooterCart*/}
                {/*scanPause={handleScanPause}*/}
                {/*ref={ref}*/}

                />
              </>

              <>


                {product ? (
                  <>
                    <div className="fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-70 z-20 backdrop-filter1"
                         onClick={productPopup}
                    ></div>
                    <div className="fixed bottom-[150px] z-50 w-screen px-4">
                      <div
                        ref={animProductRef}
                        style={{ '--translate-x': 0, '--translate-y': 0 }}
                        className={`footer-navbar  ${
                          isNavbarHidden
                            ? 'cart-item cart-item-anim visible'
                            : 'hidden'
                        }
                        rounded-full bg-white w-28 h-28 flex items-center justify-center"
                        }`}
                      >
                        <div
                          className="absolute inset-x-2/4 inset-y-2/4   -translate-y-2/4 -translate-x-2/4  flex items-center justify-center w-24 h-24">
                          <img
                            src={product.image}
                            className="max-w-full max-h-full p-2 rounded-full"
                            alt=""
                          />
                        </div>
                      </div>

                      <div
                        id="popup_product"
                        className={`footer-navbar ${
                          isNavbarHidden
                            ? 'slide-down bg-white py-4 px-8 flex flex-col justify-evenly items-center rounded-[16px]'
                            : 'bg-white py-4 px-8 flex flex-col justify-evenly items-center rounded-[16px]'
                        }`}
                      >
                        <div className="dashed flex justify-between w-full items-center w-full pb-8">
                          <div className="flex justify-start items-center  h-24">
                            <img
                              id="product_img"
                              src={product.image}
                              className="w-24 h-24 object-contain"
                            ></img>
                            <div className="h-full flex flex-col justify-center items-center py-4 ml-4  ">
                              <div className="min-30 h-full text-left">
                                <p className="mb-1 text-xl text-[#909090] ">
                                  {product.name}
                                </p>
                                <p className="text-2xl text-black">
                                  <strong> {formatPrice(product.price)} </strong>
                                </p>
                              </div>
                            </div>
                          </div>

                          {/*{qty < 2 && countItems + qty < store.item_limit ? (*/}
                          {/*  <div className="min-30_price rounded-full border-solid border-[#0000000e] px-6 py-1 flex items-center text-2xl relative">*/}
                          {/*    {qty}*/}
                          {/*    <button*/}
                          {/*      className="top-1 border-none pl-4  text-[2.5rem] relative top-px relative "*/}
                          {/*      onClick={() => incQty()}*/}
                          {/*    >*/}
                          {/*      +*/}
                          {/*    </button>*/}
                          {/*  </div>*/}
                          {/*) : (*/}
                          {/*  <>*/}
                          {/*    <div className="min-30_price rounded-full border-solid border-[#0000000e] px-6 py-1 flex items-center text-2xl relative">*/}
                          {/*      {qty >= 2 ? (*/}
                          {/*        <button*/}
                          {/*          className="border-none text-[2.5rem]  pr-4 relative "*/}
                          {/*          onClick={() => decQty()}*/}
                          {/*        >*/}
                          {/*          -*/}
                          {/*        </button>*/}
                          {/*      ) : (*/}
                          {/*        <></>*/}
                          {/*      )}*/}
                          {/*      {qty}*/}
                          {/*      {countItems + qty >= store.item_limit ? (*/}
                          {/*        <></>*/}
                          {/*      ) : (*/}
                          {/*        <button*/}
                          {/*          className="top-1 border-none pl-4 text-[2.5rem] relative top-px relative "*/}
                          {/*          onClick={() => incQty()}*/}
                          {/*        >*/}
                          {/*          +*/}
                          {/*        </button>*/}
                          {/*      )}*/}
                          {/*    </div>*/}
                          {/*  </>*/}
                          {/*)}*/}
                        </div>
                        <Menu
                          store={store}
                          product={product}
                          onCheckboxChange={handleCheckMenu} />
                        {/*<div className="flex w-full">*/}
                        {/*  <button*/}
                        {/*    className="mt-5 mb-4 py-6 justify-self-end payer text-[1.7rem] geomanistMedium w-full"*/}
                        {/*    onClick={() => addToCartHandler()}*/}
                        {/*  >*/}
                        {/*    Ajouter au panier*/}
                        {/*  </button>*/}
                        {/*</div>*/}
                      </div>
                    </div>

                  </>
                ) : (
                  <></>
                )}
                {productToAdd && (
                  <>
                    <div className="fixed w-screen h-screen top-0 left-0 bg-black bg-opacity-70 z-20 backdrop-filter1"
                         onClick={productToAddPopup}
                    ></div>
                    <div className="fixed bottom-[150px] z-50 w-screen px-4">
                      <div
                        ref={animProductRef}
                        style={{ '--translate-x': 0, '--translate-y': 0 }}
                        className={`footer-navbar  ${
                          isNavbarHidden
                            ? 'cart-item cart-item-anim visible'
                            : 'hidden'
                        }
                        rounded-full bg-white w-28 h-28 flex items-center justify-center"
                        }`}
                      >
                        <div
                          className="absolute inset-x-2/4 inset-y-2/4   -translate-y-2/4 -translate-x-2/4  flex items-center justify-center w-24 h-24">

                        </div>
                      </div>

                      <div
                        id="popup_product"
                        className={`footer-navbar ${
                          isNavbarHidden
                            ? 'slide-down bg-white py-4 px-8 flex,justify-evenly items-center rounded-[16px]'
                            : 'bg-white pt-10 pb-40 px-8 flex justify-evenly items-center rounded-[16px]'
                        }`}
                      >
                        <div className="dashed flex justify-between w-full items-center w-full pb-8">
                          <div className="flex justify-start items-center  h-24">
                            <div className="h-full flex flex-col justify-center items-center py-4 ml-4  ">
                              <div className="min-30 h-full text-left flex flex-col gap-4">
                                <p className="mb-1 text-xl text-[#909090] ">
                                  Code-Barre: {productToAdd.Code_Barre}
                                </p>
                                <div>
                                  <p>prix</p>
                                  <input type="number" id="inputProductPrice"/>
                                </div>
                                <div>
                                  <p>Nom du produit</p>
                                  <input type="text" id="inputProductName"/>
                                </div>
                                <div className='flex items-center'>
                                  <input type="checkbox" id='inputProductCheckbox' />
                                  <span>ARTICLE PRIX POIDS</span>
                                </div>


                              </div>
                            </div>
                          </div>


                        </div>

                        <div className="flex w-full">
                          <button
                            className="mt-5 py-6 justify-self-end payer text-[1.7rem] geomanistMedium w-full"
                            onClick={() => addToDatabase()}
                          >
                            Ajouter le produit
                          </button>
                        </div>
                      </div>
                    </div>

                  </>
                )}
              </>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Scan;
