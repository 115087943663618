import {
  CART_ADD_ITEM,
  CART_ADD_ITEM_REQUEST,
  CART_CREATE_FAIL,
  CART_CREATE_REQUEST,
  CART_CREATE_SUCCESS,
  CART_INFO_FAIL,
  CART_INFO_REQUEST,
  CART_INFO_SUCCESS,
  CART_PAY_FAIL,
  CART_PAY_REQUEST,
  CART_PAY_SUCCESS,
  CART_PROMO_FAIL,
  CART_PROMO_REQUEST,
  CART_PROMO_SUCCESS,
  CART_REMOVE_ITEM_FAIL,
  CART_REMOVE_ITEM_REQUEST,
  CART_REMOVE_ITEM_SUCCESS,
  CART_VERIF_FAIL,
  CART_VERIF_REQUEST,
  CART_VERIF_SUCCESS,
} from '../constants/cartConstants';
import { Toast } from '../components/Toast';
import { formatPriceWithEuro, getUserEmail } from '../utils/StringUtils';
import { notifyError, notifyWarning } from '../utils/Notify';
import { CART } from '../constants/routes';

export const createCart =
  (order, axiosInstance, callBack) => async (dispatch, getState) => {
    dispatch({ type: CART_CREATE_REQUEST, payload: order });
    try {
      const { data } = await axiosInstance.post('/orders', order, {
        promoPrice: order.promoprice,
      });
      dispatch({ type: CART_CREATE_SUCCESS, payload: data });

      localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
    } catch (error) {
      dispatch({
                 type: CART_CREATE_FAIL,
                 payload:
                   error.response && error.response.data.message
                     ? error.response.data.message
                     : error.message,
               });
    }
  };

export const addToCart =
  (orderId, product, qty, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: CART_ADD_ITEM_REQUEST });
    try {
      const toPrice = (num) => +parseFloat(num).toFixed(2);
      product.price = toPrice(product.price)
      product.Qty = qty
      const res = await axiosInstance.put(
        `orders/${orderId}/add`,
        product
      );

      dispatch({
                 type: CART_ADD_ITEM,
                 payload: res.data,
               });
      localStorage.setItem(
        'cartItems',
        JSON.stringify(getState().cart.cartItems)
      );
    } catch (err) {
      console.log(err);
    }
  };

export const removeFromCart =
  (Code_Barres_Qty, orderId, Products_Qty, axiosInstance) =>
    async (dispatch, getState) => {
      dispatch({ type: CART_REMOVE_ITEM_REQUEST });
      try {
        const res = await axiosInstance.put(
          `orders/${orderId}/delete`,
          Products_Qty
        );
        // dispatch({ type: CART_REMOVE_ITEM_SUCCESS, payload: Code_Barres_Qty });
        dispatch({ type: CART_REMOVE_ITEM_SUCCESS, payload: res.data });
      } catch (err) {
        console.log(err);
        // console.log("product not found in order " + orderId)
        dispatch({ type: CART_REMOVE_ITEM_FAIL });
      }

      // dispatch({type: CART_REMOVE_ITEM, payload:index});
      localStorage.setItem(
        'cartItems',
        JSON.stringify(getState().cart.cartItems)
      );
    };

export const getCartInfo =
  (orderId, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: CART_INFO_REQUEST, payload: orderId });
    try {
      const { data } = await axiosInstance.get(`/apiws/order/${orderId}`);
      data?.apiAvailable === false ? notifyWarning('Attention promos non disponbiles', 'En continuant, tu acceptes d\'effectuer des achats sans promotion', 'D\'accord') : <></>
      dispatch({ type: CART_INFO_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: CART_INFO_FAIL, payload: message });
    }
    localStorage.setItem(
      'cartItems',
      JSON.stringify(getState().cart.cartItems)
    );
  };
  export const getCartPromo =
  (orderId, axiosInstance) => async (dispatch, getState) => {
    dispatch({ type: CART_INFO_REQUEST, payload: orderId });
    try {
      const { data } = await axiosInstance.get(`/apiws/order/${orderId}/promo`);

      if (data?.apiAvailable === false) {
        if (window.location.pathname === '/scan') {
          notifyError(
            'Service indisponible',
            'Nos services sont indisponibles pour le moment. Nous t\'invitons à réessayer plus tard'
          )
          dispatch({ type: CART_INFO_FAIL, payload: '' });
          return { type: 'CART_INFO_FAIL' };

        } else {
          dispatch({ type: CART_INFO_SUCCESS, payload: data });
          localStorage.setItem(
            'cartItems',
            JSON.stringify(getState().cart.cartItems)
          );
          return { type: 'CART_INFO_SUCCESS' };

        }
      } else {

        dispatch({ type: CART_INFO_SUCCESS, payload: data });
        localStorage.setItem(
          'cartItems',
          JSON.stringify(getState().cart.cartItems)
        );
        return { type: 'CART_INFO_SUCCESS' };

      }

    } catch (error) {
      console.log(error)
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: CART_INFO_FAIL, payload: message });
      return { type: 'CART_INFO_FAIL' };
    }

  };

export const getPromo = (order, axiosInstance, promoItem, callBack) => async (dispatch, getState) => {
  dispatch({ type: CART_PROMO_REQUEST });
  try {
    const promoCode = promoItem.name.replace(/\s/g, '')
    const { data } = await axiosInstance.put(`/orders/${order._id}/promo/${promoItem._id}`, { order })

    switch (data.message) {
      case 'Montant minimal non atteint':
        dispatch({ type: CART_PROMO_FAIL })
        if (promoCode.slice(-1) === 'e') {
          notifyWarning('Panier inférieur à 1€', `Le panier doit être d'au moins ${parseFloat(data.promo.price) + 1}€ pour appliquer cette promotion.`);
        } else if (promoCode.slice(-1) === '%') {
          const reductionAmount = promoCode.replace('promo', '').replace('%', '');
          notifyWarning('Panier inférieur à 1€', `Le panier doit être d'au moins ${formatPriceWithEuro(100 / (100 - parseInt(reductionAmount)))} pour appliquer cette promotion.`);
        }
        break;

      case 'Montant maximal du panier 25€':
        dispatch({ type: CART_PROMO_FAIL })
        Toast('error', `Montant maximal du panier 25€`)
        break;

      case 'promo non disponible':
        // localStorage.setItem('validité', data.message)
        //verifier si le Toast existe deja
        dispatch({ type: CART_PROMO_FAIL })
        Toast('error', 'Promotions non disponible ... 😔')

        break;
      case 'promo appliquée':
        dispatch({ type: CART_PROMO_SUCCESS, payload: data.order })
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
        Toast('success', 'Promotion appliquée ! 😁')
        callBack(CART);

        break;

      case 'promotion enlevée':
        // localStorage.removeItem('validité')
        dispatch({ type: CART_PROMO_SUCCESS, payload: data.order })
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
        Toast('info', 'Promotion enlevée')
        break;

      default:
        console.log(data.message)
    }
  } catch (err) {
    dispatch({ type: CART_PROMO_FAIL });
    alert('Erreur serveur');
  }
}

export const successPaidOrder = (data) =>
  async (dispatch, getState) => {
    dispatch({ type: CART_PAY_SUCCESS, payload: data });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  };

export const failPaidOrder = (errorMessage) =>
  async (dispatch) => {
    dispatch({ type: CART_PAY_FAIL, payload: errorMessage }); // TODO : but ?
  };

export const payOrder = (order, user, paymentIntent, paymentMethod, axiosInstance) =>
  async (dispatch, getState) => {
    const email = getUserEmail(user);
    dispatch({ type: CART_PAY_REQUEST, payload: { order, email } });

    try {
      const { data } = await axiosInstance.put(`/orders/${order._id}/pay`, { email, paymentIntent, paymentMethod });
      dispatch({ type: CART_PAY_SUCCESS, payload: data });
      localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: CART_PAY_FAIL, payload: message });
    }
  };

export const verifOrder = (orderId, axiosInstance) => async (dispatch, getState) => {
  dispatch({ type: CART_VERIF_REQUEST, payload: { orderId } });
  try {
    const { data } = await axiosInstance.put(`/orders/${orderId}/verif`);
    dispatch({ type: CART_VERIF_SUCCESS, payload: data });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));

  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: CART_VERIF_FAIL, payload: message });
  }
};
