import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardInfoForUser from '../styles/img/CardInfoForUser.png';
import PointsInfoForUser from '../styles/img/PointsInfoForUser.svg';
import carteCadeauInfoForUser from '../styles/img/carteCadeauInfoForUser.svg';
import mystereInfoForUser from '../styles/img/mystereInfoForUser.svg';
import donInfoForUser from '../styles/img/donInfoForUser.svg';
import virementInfoForUser from '../styles/img/virementInfoForUser.svg';
import CrossInfoForUser from '../styles/img/CrossInfoForUser.svg';
import { useNavigate } from 'react-router-dom';
import { findUser } from '../actions/userActions';
import useAxiosInterceptors from '../axios/useAxios';
import PrimaryButton from './button/PrimaryButton';

const UserInfo = () => {
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInterceptors();
    const [brands, setBrands] = useState([]);


    const user = useSelector((state) => state.user);

    useEffect(() => {
      window.scrollTo(0, 0)
      if(user && user._id && user.email) {
        dispatch(findUser(user.email, axiosInstance, navigate))
      }
      axiosInstance.get('/brands')
        .then((response) => {
          setBrands(response.data);
        })
    }, [])

    useEffect(() => {
      const popupUser = localStorage.getItem('popupUser')
      if(user && user._id && !popupUser){
        setOpen(true)
        setOpen2(true)
        localStorage.setItem('popupUser', true)
      }
    }, [user]);

    const Jcompris = () => {
      setOpen2(false)
      setTimeout(() => {
        setOpen(false)
      }, 600);

    }
    const varstyle = {
      boxShadow: open ? "0 0 0 100vmax rgb(0 0 0 / 80%)" : "",
      background: "linear-gradient(180deg, rgba(219, 255, 0, 0.10) 0%, rgba(41, 221, 81, 0.10) 100%), var(--color-primary-white, #FFF)"
    }

    return (
      open2 ?
      <div className={`transition-all duration-700 h-[100dvh] fixed w-[100vw] z-40 left-0 top-0 overflow-x-visible`} onClick={Jcompris}>
        <div className={`${open2?"z-[50]  w-[100%] transform overflow-x-visible translate-x-[-50%] pt-[3.5em] left-[50%] gap-8 mx-auto flex flex-col  items-center bottom-0 transition-all h-full fixed  shadow-lg duration-700 slideuppopupXY ":"z-[50] p-8 w-[100%] transform translate-x-[-50%] left-[50%] gap-8 mx-auto flex flex-col pt-20 items-center rounded-tr-[20px] bottom-0 transition-all  rounded-tl-[20px] fixed  shadow-lg duration-700 "}`}
        style={varstyle}>

          <div className="slider-for-user-scroll-box overflow-x-visible p-8 pt-0" style={{fontFamily: 'geomanistregular'}}>
                
                <div className="flex flex-col gap-4 ">
                  <p className="text-5xl geomanistBold" style={{fontFamily: 'geomanistmedium'}}>Cumule des</p>
                  <img src={PointsInfoForUser} alt="points"/>
                </div>


                {/* <div className="slider-for-user-brand-box">
                    <BrandList brands={brands} disableClick />
                </div> */}
                <div className="bg-white px-2 py-10 w-full flex rounded-2xl  overflow-visible relative ">
                  <div className="flex flex-col justify-center px-6 w-[70%]">
                    <span style={{color: "#7C6D6D"}} className="text-2xl">Comment ?</span>
                    <span className="text-3xl "style={{fontFamily: 'geomanistmedium'}}>En utilisant PikkoPay pour tes achats !</span>
                  </div>
                  <div className="h-full flex items-center overflow-visible ">
                    <img src={CardInfoForUser} alt="Partner" className="absolute bg-none right-[-2em]  w-[44%] h-auto"/>

                  </div>

                </div>
                <div className="bg-white w-full flex rounded-2xl p-8 gap-2">
                 <div className="flex flex-col justify-center ">
                    <span style={{color: "#7C6D6D"}} className="text-2xl">Qu'en faire?</span>
                    <span className="text-3xl text-left" style={{fontFamily: 'geomanistmedium'}}>Les convertir en cadeaux</span>
                  </div>

                  <div class="containerGift">
                    <div class="flex gap-4">
                      <div className="gift_card_user_info">
                        <img src={carteCadeauInfoForUser} alt="giftcard" />
                      </div>
                      <div className="gift_card_user_info">
                        <img src={virementInfoForUser} alt="virement" />
                      </div>
                    </div>
                    <div class="flex gap-4">
                    <div className="gift_card_user_info">
                      <img src={donInfoForUser} alt="donation" />
                    </div>
                
                    </div>
                  </div>

                </div>
          </div>
          <div className='w-[92%] flex justify-center mb-4'>
             <PrimaryButton onClick={Jcompris} label={"J'ai compris"} />
          </div>
          {/* <button onClick={Jcompris} class="slider-btn">J'ai compris</button> */}
        </div>
      </div>
        :<></>

    );
    }

export default UserInfo;
