import React, { forwardRef, useState } from 'react';
import PaygreenBankCardForm from './PaygreenBankCardForm';

const PaygreenUserForm = ({
                            user,
                            userEmail,
                            userName,
                            setUserName,
                            setUserEmail,
                            handleSaveCard,
                            errorMessage,
                            loading,
                            cardNbComplete,
                            cardExpComplete,
                            cardCVCComplete,
                            cartItems,
                            onInitPaygreenEventListeners,
                            initPaygreen,
                            paymentEventTriggered,
                            conecs,
                            handleSubmit,
                            save,
                            paymentInputLoading,
                            showPaymentFields,
                            createBuyer,
                          }) => {
  const [showCardDetails, setShowCardDetails] = useState(false);
  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };
  const handleInputChange2 = (e) => {
    setUserEmail(e.target.value);
  };

  const onClick = () => {
    // TODO : add verif
    createBuyer(() => setShowCardDetails(true));
  };

  return (
    <div
      id="paygreen-conecs"
      style={{ fontFamily: 'Open Sans' }}
      className="mt-6 slide-down-transition slide-down-content visible grey-bg"
    >
      <div
        className="mailformpayment margin-top mailformpayment--name shadow-[0px 1px 2px rgba(0, 0, 0, 0.25)] rounded-[1em]">
        <input className="shadow-md" maxLength="20" placeholder="Nom et prénom sur la carte" type="text"
               name="name" id="nomprenom" value={userName === ' ' ? '' : userName}
               onChange={handleInputChange}
               autoComplete="name" />
      </div>
      {!user.email && (
        <div className="mailformpayment margin-top shadow-[0px 1px 2px rgba(0, 0, 0, 0.25)] rounded-[1em]t">
          <input className="shadow-md" placeholder="Email" type="text" name="name" id="nomprenom"
                 autoComplete="email"
                 value={userEmail === '' ? '' : userEmail} onChange={handleInputChange2} />
        </div>
      )}
      {showCardDetails
        ? (
          <PaygreenBankCardForm
            handleSaveCard={handleSaveCard}
            errorMessage={errorMessage}
            loading={loading}
            cardNbComplete={cardNbComplete}
            cardExpComplete={cardExpComplete}
            cardCVCComplete={cardCVCComplete}
            cartItems={cartItems}
            onInitPaygreenEventListeners={onInitPaygreenEventListeners}
            initPaygreen={initPaygreen}
            paymentEventTriggered={paymentEventTriggered}
            conecs={conecs}
            handleSubmit={handleSubmit}
            save={save}
            showPaymentFields={showPaymentFields}
            paymentInputLoading={paymentInputLoading}
          />)
        : (
          <button
            className={'payer_maintenant_btn flex items-center'}
            onClick={onClick}
          >
            <p className="font-light" style={{ fontFamily: 'geomanistmedium' }}>Suivant</p>
          </button>
        )
      }
    </div>
  );
};
export default forwardRef(PaygreenUserForm);
