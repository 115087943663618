

// targetNode: c'est l'element qui contient le drawer ---> ID du drawer
// open: c'est une fonction qui permet de changer l'etat du drawer
// cette fonction permet de detecter la position du drawer et de changer l'etat du drawer en fonction de sa position

export const PositionDetector = (targetNode,open) => {
    if (!targetNode) return;
    try{
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const cssText = targetNode.style.cssText;
          const cssstyleObj = cssText.split(';').reduce((acc, item) => {
            const [key, value] = item.split(':');
            if (key && value) {
              acc[key.trim()] = value.trim();
            }
            return acc;
          }, {});

          if (cssstyleObj['--rsbs-overlay-h']) {
            if(parseInt(cssstyleObj['--rsbs-overlay-h'].split('px')[0]) <100){
                setTimeout(() => {
                    open(false)
                }, 100);
              
            }
            else{
              open(true)
            }
          }
        }
      }
    });

    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['style'],
    });

    // netoyage de l'observer
    return () => {
      observer.disconnect();
    };}
    catch(e){
        console.log(e)
    }

    };
