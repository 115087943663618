export const CART_CREATE_REQUEST = "CART_CREATE_REQUEST";
export const CART_CREATE_SUCCESS = "CART_CREATE_SUCCESS";
export const CART_CREATE_FAIL = "CART_CREATE_FAIL";
export const CART_CREATE_PARTIAL_RESET = "CART_CREATE_PARTIAL_RESET";
export const CART_CREATE_RESET = "CART_CREATE_RESET";

export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";

export const CART_REMOVE_ITEM_REQUEST = "CART_REMOVE_ITEM_REQUEST";
export const CART_REMOVE_ITEM_SUCCESS = "CART_REMOVE_ITEM_SUCCESS";
export const CART_REMOVE_ITEM_FAIL = "CART_REMOVE_ITEM_FAIL";

export const CART_INFO_REQUEST = "CART_INFO_REQUEST";
export const CART_INFO_SUCCESS = "CART_INFO_SUCCESS";
export const CART_INFO_FAIL = "CART_INFO_FAIL";

export const CART_PROMO_REQUEST = "CART_PROMO_REQUEST";
export const CART_PROMO_SUCCESS = "CART_PROMO_SUCCESS";
export const CART_PROMO_FAIL = "CART_PROMO_FAIL";

export const CART_PAY_REQUEST = "CART_PAY_REQUEST";
export const CART_PAY_SUCCESS = "CART_PAY_SUCCESS";
export const CART_PAY_FAIL = "CART_PAY_FAIL";
export const CART_PAY_RESET = "CART_PAY_RESET";

export const CART_VERIF_REQUEST = "CART_VERIF_REQUEST";
export const CART_VERIF_SUCCESS = "CART_VERIF_SUCCESS";
export const CART_VERIF_FAIL = "CART_VERIF_FAIL";

export const CART_EMPTY = "CART_EMPTY";
