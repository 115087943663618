import React, { useEffect, useState } from 'react';
import Config from '../../../../axios/Config';
import { ADD_FID_CARD, USER_FID_CARD } from '../../../../constants/routes';
import { confirmWarning, notifyInfo, notifyWarning } from '../../../../utils/Notify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import FidScanScreen from './FidScanScreen';
import { addFidCard } from '../../../../actions/userActions';
import { identify } from '../../../../actions/fidelityActions';

const UserAddFidScanScreen = () => {
  const navigate = useNavigate();
  const axiosInstance = Config();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)
  const {cartItems} = useSelector(state => state.cart)
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaResultToken, setRecaptchaResultToken] = useState('');
  const [fidelityCardId, setFidelityCardId] = useState(undefined);

  const { state } = useLocation();

  const onVerification = (fidCardId) => {
    if (!fidCardId) {
      notifyWarning('Vous devez saisir un numéro de carte de fidélité.');
      return;
    }

    const isCardWillBeReplaced = user?.fidCard && user.fidCard
      .some((card) => state?.fidCard?._id === card.fidelityCardBddId);
    const isCardAlreadyAdded = user?.fidCard && user.fidCard
      .some((card) => fidCardId === card.fidelityCardPhysicalId)

    if (isCardWillBeReplaced && isCardAlreadyAdded) {
      notifyInfo('Tu as déjà ajouté cette carte à ton compte.');
    } else if (isCardWillBeReplaced) {
      confirmWarning( // title, text, confirmText, callBack
        'Tu as déjà une carte de fidelité',
        'Tu possèdes déjà une carte de fidélité avec cette enseigne. Souhaites-tu la remplacer ?',
        'Oui',
        () => addCard(fidCardId)
      );
    } else {
      addCard(fidCardId);
    }
  }

  const onIdentifySuccess = (fidCardId, data) => {
    if (data.userMaskedEmail) {
      notifyWarning('Cette carte déjà associée à un compte');
    } else {
      dispatch(addFidCard(axiosInstance, user._id, fidCardId, state.fidCard.companyId, () => navigate(USER_FID_CARD)));
    }
  };

  const addCard = (fidCardId) => {
    setIsLoading(true);
    identify(
      axiosInstance, user, cartItems, fidCardId, recaptchaResultToken,
      (data) => onIdentifySuccess(fidCardId, data),
      () => setIsLoading(false)
    );
  }

  const goToCardScreen = () => {
    navigate(ADD_FID_CARD, {state: { fidCard : state.fidCard }});
  };

  useEffect(() => {
    if (fidelityCardId) {
      onVerification(fidelityCardId);
      setFidelityCardId(undefined); // To re initialize scanner behavior
    }
  }, [fidelityCardId]);

  return (
     <FidScanScreen
       onScan={setFidelityCardId}
       fidScanLoading={isLoading}
       goToCardScreen={goToCardScreen}
       companyLogo={state?.fidCard?.companyImage}
       setRecaptchaResultToken={setRecaptchaResultToken}
     />
  );
};

export default UserAddFidScanScreen;
