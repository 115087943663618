import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import {Provider} from 'react-redux'
import './styles/index.scss'
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import { AuthProvider } from './context/authProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// import { AuthProvider } from './context/authProvider';

// import {Elements} from '@stripe/react-stripe-js'
// import {loadStripe} from '@stripe/stripe-js'




navigator.serviceWorker.getRegistrations()
  .then(registrationsArray => {
    if(registrationsArray && registrationsArray[0].update()) registrationsArray[0].update();

  })
  .catch(e => {
    console.log(e)
  })



ReactDOM.render(


  <AuthProvider>
    <Provider store ={store}>
        {/* <React.StrictMode> */}
      <GoogleReCaptchaProvider reCaptchaKey="6LfjOt0pAAAAAFcEvh1DMhOaK2d-2f5o-J3Wa_cc">
        <App />
      </GoogleReCaptchaProvider>
        {/* </React.StrictMode> */}
    </Provider>
    </AuthProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.unregister();
// Change serviceWorkerRegistration.unregister() to serviceWorkerRegistration.register()
serviceWorkerRegistration.register({
  onUpdate: registration => {
    // If there is an update available, force the service worker to update
    if (registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Refresh the page or notify the user to refresh manually
      window.location.reload();
    }
  }
});

