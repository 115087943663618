import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CART_CREATE_RESET, CART_EMPTY } from '../constants/cartConstants';
import { ID_RESET } from '../constants/idConstants';
import { getStore } from '../actions/storeActions';
import { STORE_RESET, STORE_UNAVAILABLE_ALERT_TRIGGER } from '../constants/storeConstants';
import useAxiosInterceptors from '../axios/useAxios';
import HomeLoader from '../components/loader/HomeLoader';
import { userInitialPromo } from '../actions/userActions';
import { createCart } from '../actions/cartActions';
import { STORE_LIST } from '../constants/routes';

const RedirectPage = () => {
  // if get store success alors GO
  const navigate = useNavigate();
  const [reset, setReset] = useState(false);
  const id = useSelector((state) => state.id);
  const { store } = useSelector((state) => state.store);
  const user = useSelector((state) => state.user)
  const conecs = useSelector((state) => state.conecs)
  const edenred = useSelector((state) => state.edenred)
  const creditCard = useSelector((state) => state.creditCard)
  const { success, loading, cartItems } = useSelector((state) => state.cart);
  const [isLoading, setIsLoading] = useState(true);

  // const axiosInstance = Config()
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();
  console.log(axiosInstance);

  const location = new URLSearchParams(window.location.search).get('ok');
  const cb = new URLSearchParams(window.location.search).get('cb');
  console.log(cb)
  useEffect(() => {
    // if( window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches){
    //   alert('Mode application instable.. Veuillez quitter et scanner un QR CODE')
    // }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if(edenred && edenred.username && edenred.username.length > 0){
      user.edenred = edenred
      const newUser = JSON.parse(localStorage.getItem('user')) || {}
      newUser.edenred = edenred
      localStorage.setItem('user', JSON.stringify(newUser))
      // localStorage.removeItem('Edenred')
    }

    if(conecs && conecs.buyerId && conecs.buyerId.length > 0 && conecs.reuse === true){
      user.conecs = conecs
      const newUser = JSON.parse(localStorage.getItem('user')) || {}
      newUser.conecs = conecs
      localStorage.setItem('user', JSON.stringify(newUser))
      // localStorage.removeItem('conecs')

    }
    if(creditCard && creditCard.id && creditCard.id.length > 0 && creditCard.saveCard === true){
      console.log([creditCard])
      user.creditCard = [creditCard]
      console.log(user)
      const newUser = JSON.parse(localStorage.getItem('user')) || {}
      newUser.creditCard = [creditCard]
      localStorage.setItem('user', JSON.stringify(newUser))

    }
    if(cb){
      // console.log('oopo')
      // const newUser = {}
      // newUser.creditCard = [JSON.parse(cb)]
      // console.log(newUser)
      // localStorage.setItem('user', JSON.stringify(newUser))
      user.creditCard = [JSON.parse(cb)]
    }

    dispatch(userInitialPromo(user, axiosInstance))

    dispatch({ type: CART_EMPTY });
    dispatch({ type: ID_RESET });
    dispatch({ type: CART_CREATE_RESET});
    dispatch({ type: STORE_RESET });
    setReset(true);
    // dispatch(removeConecs(conecs,'autoRemove'))
    // dispatch(removeCB(creditCard, 'autoRemove'))
    localStorage.removeItem('receipt_url')
    localStorage.removeItem('animSuccess')
    localStorage.removeItem('welcome')
  }, []);

  useEffect(() => {
    // if(order){
    //     dispatch(getClient(order.order.clientId, axiosInstance))
    //     dispatch(listMenus(axiosInstance))
    // }
    if (location) {
      dispatch(getStore(location, axiosInstance));
    }
  }, [location]);

  useEffect(() => {
    if (store && !store.live) {
      dispatch({type: STORE_RESET});
      localStorage.setItem(STORE_UNAVAILABLE_ALERT_TRIGGER, JSON.stringify({ isTriggered: false }));
      navigate(STORE_LIST, { state: { storeLocked: true } });
      return;
    }
    if (store && reset) {
      const orderCreate = { storeId: store.id, clientId: "" };
      console.log("new order stooore");
      try {
        dispatch(createCart(orderCreate, axiosInstance));
      } catch (e) {
        console.log(e);
      }
    }
  }, [store]);

  return (
    /*<>
      {isLoading ? (
        <HomeLoader />
      ) : (*/
        <>
          {success && !isLoading ? (
            <>
            {//isPageLoading ? <><HomeLoader/></> :
            <div> {navigate("/home")} </div>
            }
            </>
          ) : (
            <>

              {location ? (
                <> <HomeLoader /></>
              ) : (
                <>
                {navigate('/store-list')}
                {/* <div
                  id="loader"
                  class="loader loader-default is-active"
                  data-text="Scannez un QR code"
                ></div>  */}
                </>
              )}
            </>
          )}
        </>
      //)}
    //</>

    /////
    /*<>
      {success ? (
        <div> {navigate("/scan")} </div>
      ) : (
        <>
          <div id="loader" class="loader loader-default is-active"></div>

          {location ? (
            <>
              <div id="loader" class="loader loader-default is-active"></div>
            </>
          ) : (
            <div
              id="loader"
              class="loader loader-default is-active"
              data-text="Scannez un QR code"
            ></div>
          )}
        </>
      )}
    </>*/

    //////////////////////////

    // <>
    // {loading ? (
    //     <>
    //     <div id="loader" class="loader loader-default is-active"></div>

    //     </>
    // ) : (

    //         <>
    //             {success ?

    //                 <>

    //                     <div>
    //                     {navigate('/scan')}
    //                     </div>

    //                     </>
    //                 :(
    //                     <>
    //                     {location ? (
    //                         <>
    //                         {/* <div id="loader" class="loader loader-default is-active"></div> */}
    //                         <div>{navigate('/scan')}</div>
    //                         </>
    //                     ) : (
    //                         <>
    //                         <div id="loader" class="loader loader-default is-active" data-text="Scannez un QR code"></div>
    //                         </>

    //                     )}

    //                     </>
    //                 )}
    //         </>

    //     )}

    // </>
  );
};

export default RedirectPage;

// <div id="loader" class="loader loader-default is-active" data-text="Scannez un QR code"></div>
