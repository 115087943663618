// export const MINIMUM_PRICE = 0.01;
export const MINIMUM_PRICE = 1;
export const isAllowedToPay = (cartItems) => {
  return cartItems && (cartItems.promoPrice >= MINIMUM_PRICE
    || (cartItems.promoPrice === 0 && cartItems.itemsPrice >= MINIMUM_PRICE));
};

export const isAllowToChangeStore = (store) => {
  const exclusiveStores = process.env.REACT_APP_EXCLUSIVE_STORES.split(',');
  return !exclusiveStores.includes(store.name.toUpperCase());
}