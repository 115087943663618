import React, { forwardRef, useEffect, useImperativeHandle, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dell from '../../styles/img/dell.svg';
import { getOrderAmount } from '../../utils/PriceUtils';
import useAxiosInterceptors from '../../axios/useAxios';
import { successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo, updatePayzenCards } from '../../actions/userActions';
import { getUserEmail } from '../../utils/StringUtils';

const PayzenCreditCard = ({ onCheckboxChange, paymentMethod, paymentErrorMessage, resetPaymentMethod, payzenError, payzenToken }, ref) => {
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const payzenCreditCards = useSelector((state) => state.user.payzenCreditCards) || {}
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [isContentVisible, setIsContentVisible] = useState(false)
  const [saveCard, setSaveCard] = useState(false);
  const [loading, setLoading] = useState(false);

  const isChecked = paymentMethod === 'cb';

  const handleCheckboxChange = () => {
    onCheckboxChange();
  };

  useEffect(() => {
    if (cartItems.isPaid === true) {
      setLoading(false)
    }
  }, [cartItems.isPaid]);

  const deletePayzenCreditCard = (payzenCreditCards) => {
    dispatch(updatePayzenCards(payzenCreditCards));
    resetPaymentMethod();
    alert('Rafraichissement de la page pour mettre à jour les paiements');
    window.location.reload();
    // setIsContentVisible(false);
  };

  const handleDeletePayzenCreditCard = () => {
    if (!user._id) {
      deletePayzenCreditCard([]);
      return;
    }

    axiosInstance.put('/payzen/delete-card',
      {
        paymentMethodToken: payzenCreditCards[0].pm,
        userId: user._id
      })
      .then((response) => {
        deletePayzenCreditCard(response.data.payzenCreditCards);
      })
      .catch((error) => console.log({ error }));
  }

  const handleSubmit = async () => {
    const email = getUserEmail(user);

    axiosInstance.post('/payzen/pay',
      {
        amount: getOrderAmount(cartItems),
        orderId: cartItems._id,
        storeId: cartItems.storeId,
        userEmail: email,
        paymentMethodToken: payzenCreditCards[0].pm
      })
      .then((response) => {
        dispatch(successPaidOrder(response.data));
        dispatch(addUsedPromo(user, axiosInstance, cartItems));
      })
      .catch((error) => console.log({ error }));
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }))

  const handleShowCreditCard = (e) => {
    handleCheckboxChange();
    if (e.target.checked) {
      setIsContentVisible(true)
    } else {
      setIsContentVisible(false)
    }
  };

  const handleSaveCard = (state) => {
    let checkbox = document.querySelector('.checkbox');
    if (state.target.checked) {
      checkbox.classList.add('checked_svg');
    } else {
      checkbox.classList.remove('checked_svg');
    }
    setSaveCard(!saveCard);
  };

  useEffect(() => {
    setIsContentVisible(isChecked);
  }, [isChecked]);

  return (
    <>
      {payzenCreditCards && payzenCreditCards[0]?.last4 ? (
        <div className="flex items-center formcarte text-black px-8 pr-14 w-full">
          <div className='flex w-full' onClick={handleCheckboxChange}>
            <input
              type="checkbox"
              checked={isChecked}
              id="choicecb"
              onChange={() => {
                handleCheckboxChange();
                // toggleAccordion();
              }}
            />
            <label className="cbchecklabel mr-4" ></label>
            <div className="flex flex-row items-end gap-8 items-center">
              <img className="h-10 rounded-[5px] mr-2 " src={payzenCreditCards[0].img} />
              <div className="text-xl">.... {payzenCreditCards[0].last4} </div>
            </div>
          </div>
          <button
            onClick={handleDeletePayzenCreditCard}
            className="deletepayment"
            style={{ marginLeft: 'auto' }}
          >
            <img src={dell} alt="" />
          </button>
        </div>

      ) : (
        <div style={isContentVisible ? { boxShadow: '0px 3px 20px -10px rgba(0, 0, 0, 0.25)' } : {}}>
          <div
            onClick={handleCheckboxChange}
            className={`flex items-center formcarte text-black px-8 pr-14 w-full ${isContentVisible ? 'slide-down-btn open' : ''}`}
          >
            <div className="cbandlabel flex  w-full">
              <div className="flex  w-[70%] items-center">
                <input
                  id="cbCheckbox"
                  name="cbCheckboxview"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleShowCreditCard}
                />
                <label className="cbchecklabel mr-4" onClick={handleCheckboxChange} />
                <p className="text-[16px]" style={{ fontFamily: 'geomanistRegular' }}>Carte bancaire</p>
              </div>
              <div className="cbimg flex no-wrap">
                <img
                  className="h-10 rounded-[5px]"
                  src="https://firebasestorage.googleapis.com/v0/b/gensheet-b606a.appspot.com/o/Amex.png?alt=media&token=ddb2a999-aaf8-47a0-91e6-7346a0242214"
                />
                <img
                  className="h-10"
                  src="https://firebasestorage.googleapis.com/v0/b/gensheet-b606a.appspot.com/o/Visa.png?alt=media&token=56967d89-2fc1-490e-94a4-631810baca52"
                />
                <img
                  className="h-10"
                  src="https://firebasestorage.googleapis.com/v0/b/gensheet-b606a.appspot.com/o/Mastercard.png?alt=media&token=4448318a-223e-4511-aa96-410b5da62144"
                />
              </div>
            </div>
          </div>
          <div>
            <div
              id="cbpopup" style={{ fontFamily: 'Open Sans' }}
              className={` slide-down-transition ${
                isContentVisible
                  ? 'slide-down-content visible '
                  : 'slide-down-content'
              }`}
            >
              <input className="mr-4" id="cbcheckbox" type="checkbox" checked={saveCard} onChange={handleSaveCard} />

              {payzenToken && (
                <div className="kr-smart-form" kr-card-form-expanded kr-form-token={payzenToken}></div>
              )}
              {payzenError && (
                <div className="kr-custom-error">{payzenError}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default forwardRef(PayzenCreditCard);







