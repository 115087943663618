import React, { useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import BackButton from '../../../../components/button/BackButton';
import PrimaryButton from '../../../../components/button/PrimaryButton';
import cam from '../../../../styles/img/account/cam.svg';
const FidCardScreen = ({ onConfirm, onBack, goToScan, companyLogo,companyName, setRecaptchaResultToken, isLoading }) => {
  const [fidCardId, setFidCardId] = useState(undefined);

  return (
    <div className="flex flex-col justify-start relative top-0 px-10 bg-gradient1 h-screen w-screen">
      <div className="fixed top-4 left-4 w-full h-16  flex items-center justify-between px-4 z-50">
        <BackButton onClick={onBack} isLogoVisible={true} />
      </div>
      <div className="contenaireNumberFidelity">
        <div className="titre_fidelityscreen">
          Saisir le numéro imprimé sur la carte
        </div>
      
        <div className='input_box_fidScreen'>
           <input type="text" name="number" id="" placeholder="Saisir mon numéro client" className="input "
               onChange={(event) => setFidCardId(event.target.value)} />
               <img src={companyLogo} alt="logo" className="store_logo" />
        </div>
        
       
        <button onClick={goToScan} className="relative btn_fidelitycardScreen ">
          Scanner ma carte
          <img src={cam} alt="cam" className="cam" />
        </button>
        {setRecaptchaResultToken && (
          <div className="relative top-10">
            <GoogleReCaptcha onVerify={setRecaptchaResultToken} action="login" />
          </div>
        )}
        <div className="bottomBtn fixed bottom-4 w-full flex justify-center px-8 pb-8">
          <PrimaryButton onClick={() => onConfirm(fidCardId)} label="Suivant" isLoading={isLoading} />
        </div>
      </div>
      
    </div>
  );
};

export default FidCardScreen;

